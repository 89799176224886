import React, { useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import {
  Column,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useQuery } from 'react-query';
import {
  fetchTransferHistory,
  PartnerTransfer,
  PartnerTransferShipment,
} from '../../../api/partnerTransfers';
import Message from '../../Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import LoadingIndicator from '../../Form/LoadingIndicator';
import SimpleReactTable from '../../Tables/SimpleReactTable';
import AuditNextPrevPaginator from './AuditNextPrevPaginator';
import NewTabLink from '../../NewTabLink';
import useOrgId from '../../../hooks/useOrgId';

type ShipmentWithTransferId = PartnerTransferShipment & {
  transfer_id: PartnerTransfer['id'];
  items: PartnerTransfer['items'];
};

export default function WarehouseAuditShipmentsTable({}: {}) {
  const navigate = useNavigate();
  const orgId = useOrgId();
  const warehouseId = useCurrentWarehouseId();
  const [params] = useSearchParams();
  const pageNumber = Number(params.get('page')) || 1;

  const {
    data: transfers,
    error,
    isLoading,
    isSuccess,
  } = useQuery(['transfers'], () => fetchTransferHistory(warehouseId!), {
    enabled: !!warehouseId,
  });

  const shipmentsWithTransferIdAndItems = useMemo(
    () =>
      transfers?.flatMap<ShipmentWithTransferId>(transfer =>
        (transfer.shipments || []).map(shipment => ({
          ...shipment,
          transfer_id: transfer.id,
          items: transfer.items,
        })),
      ) || [],
    [transfers],
  );

  const table = useReactTable({
    data: shipmentsWithTransferIdAndItems || [],
    columns: useMemo(
      () => [
        {
          accessorFn: shipment => {
            if (!shipment.tracking_number) {
              return 'N/A';
            }

            if (shipment.tracking_number.length > 10) {
              return '...' + shipment.tracking_number.slice(-10);
            } else {
              return shipment.tracking_number;
            }
          },
          header: 'Tracking Number',
          cell: info => (
            <NewTabLink
              href={info.row.original.tracking_url}
              className="text-blue-500"
            >
              {info.getValue()}
            </NewTabLink>
          ),
        } satisfies ColumnDef<
          ShipmentWithTransferId,
          PartnerTransferShipment['tracking_number']
        >,
        {
          id: 'created_at',
          accessorFn: shipment => shipment.created_at,
          header: 'Created',
          cell: info => new Date(info.getValue()).toLocaleDateString(),
        } satisfies ColumnDef<
          ShipmentWithTransferId,
          PartnerTransferShipment['created_at']
        >,
        {
          id: 'delivered_at',
          accessorFn: shipment => shipment.delivered_at,
          header: 'Delivered',
          cell: info => {
            const date = info.getValue();
            return date ? new Date(date).toLocaleDateString() : 'N/A';
          },
        } satisfies ColumnDef<
          ShipmentWithTransferId,
          PartnerTransferShipment['delivered_at']
        >,
        {
          header: 'Status',
          cell: info => {
            const totalCount = info.row.original.items?.length || 0;
            const countOfCompleted =
              info.row.original.items?.filter(
                item =>
                  item.status === 'AUDIT_ACCEPTED' ||
                  item.status === 'AUDIT_MODIFIED' ||
                  item.status === 'AUDIT_REJECTED',
              ).length || 0;

            if (info.row.original.delivered_at == null) {
              return `In Transit`;
            }

            if (countOfCompleted == 0) {
              return `Delivered`;
            }

            if (countOfCompleted == totalCount) {
              return `Completed (${countOfCompleted} / ${totalCount})`;
            } else {
              return `Processing (${countOfCompleted} / ${totalCount})`;
            }
          },
        } satisfies ColumnDef<ShipmentWithTransferId>,
        {
          header: 'Actions',
          cell: info => {
            if (info.row.original.shipment_status === 'DELIVERED') {
              return (
                <Link
                  to={`/warehouse/${warehouseId}/audit/transfers/${info.row.original.transfer_id}`}
                  className="font-semibold text-green-500"
                >
                  Finish Processing
                </Link>
              );
            }
          },
        } satisfies ColumnDef<ShipmentWithTransferId>,
      ],
      [],
    ),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <div>
        <Message error>{getErrorMessage(error)}</Message>
      </div>
    );
  }

  if (isLoading && !transfers) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      {isSuccess && transfers?.length === 0 ? (
        <div className="py-6 text-center text-lg font-semibold text-slate-green-500">
          No transfers
        </div>
      ) : (
        <>
          <SimpleReactTable table={table} />
          {/*<div className="mt-8 flex justify-center">*/}
          {/*  <AuditNextPrevPaginator paging={meta?.paging} />*/}
          {/*</div>*/}
        </>
      )}
    </>
  );
}
