import React, { useEffect } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  fetchTransferById,
  PartnerTransferItem,
} from '../../../api/partnerTransfers';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../../../components/Tables/SimpleReactTable';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { Button } from '../../../components/Form/Button';
import { PrinterIcon } from '@heroicons/react/24/outline';
import NewTabLink from '../../../components/NewTabLink';
import formatListingTag from '../../../utils/formatListingTag';
import usePartnerUserName from '../../../hooks/query/usePartnerUserName';
import { fireClickedPrintPackingSlip } from '../../../services/analytics/partner/transfers';

const columns: ColumnDef<PartnerTransferItem>[] = [
  {
    header: 'Product Name',
    accessorFn: row => row.name,
  },
  {
    header: 'Tag ID',
    accessorFn: row => (row.tag ? formatListingTag(row.tag) : null),
  },
  {
    header: 'Processed By',
    cell: ({ row }) => {
      return usePartnerUserName(row.original.created_by);
    },
  },
  {
    header: 'Trade-in Date',
    accessorFn: row => new Date(row.created_at).toLocaleDateString(),
  },
];

interface Props {}

const PartnerDashboardTransferPrintablePackingSlipPage: React.FC<
  Props
> = () => {
  const { transferId } = useParams<{ transferId: string }>();
  const { data: transfer, error: transferError } = useQuery(
    ['transfer', transferId],
    () => fetchTransferById(transferId!),
  );

  useEffect(() => {
    if (transfer?.id) {
      fireClickedPrintPackingSlip(transfer.id);
    }
  }, [transfer?.id]);

  const table = useReactTable({
    columns,
    data: transfer?.items || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PageLayout
      header={
        <>
          <div className="flex items-center space-x-4">
            <PageHeader className="flex-1">Packing List</PageHeader>
            <Button
              onClick={() => window.print()}
              className="flex items-center space-x-3 print:hidden"
            >
              <PrinterIcon className="h-5 w-5" />
              <span>Print</span>
            </Button>
          </div>
        </>
      }
    >
      {!!transferError && (
        <Message error>{getErrorMessage(transferError)}</Message>
      )}

      {transfer && (
        <div className="mb-6 space-y-2">
          <div className="text-lg">
            Shipment Date: {new Date(transfer.created_at).toLocaleDateString()}
          </div>

          <div>
            <div className="mb-1 text-lg">Tracking Numbers:</div>
            <ul className="space-y-0.5 pl-4">
              {transfer.shipments.map(shipment => (
                <li key={shipment.tracking_number}>
                  <NewTabLink
                    href={shipment.tracking_url}
                    className="text-blue-500 underline"
                  >
                    {shipment.tracking_number}
                  </NewTabLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <SimpleReactTable table={table} />
    </PageLayout>
  );
};

export default PartnerDashboardTransferPrintablePackingSlipPage;
