import React, { useState } from 'react';
import {
  FullCategory,
  ValueGuideMeta,
  ValueGuideTimeToSell,
} from '../../api/intf/item';
import LoadingIndicator from '../Form/LoadingIndicator';
import displayPrice from '../../utils/displayPrice';
import thousandsSeparator from '../../utils/thousandsSeparator';
import NewTabLink from '../NewTabLink';
import qsStringify from '../../utils/qsStringify';
import { useFormikContext } from 'formik';
import { CreateItemFormValues } from './CreateItemForm';
import SimpleModal from '../Modal/SimpleModal';
import ValueGuideDataTable from './ValueGuideDataTable';
import RecentlySoldModal from './RecentlySoldModal/RecentlySoldModal';
import { useQuery } from 'react-query';
import { fetchRetailValueGuide } from '../../api/item';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { getSellThroughRate } from '../../utils/getSellThroughRate';
import { getLast } from '../../utils/getLast';
import generateItemTitle from '../../utils/generateItemTitle';
import classNames from 'classnames';
import { Button } from '../Form/Button';
import { SELL_THROUGH_RATE_HIGH } from '../../utils/determineShouldBuyItem';

type BuyStatus = 'buy' | 'consider' | 'no';

function buyStatusToLabel(buyStatus: BuyStatus) {
  if (buyStatus === 'buy') {
    return 'Buy';
  } else if (buyStatus === 'consider') {
    return 'Consider';
  } else if (buyStatus === 'no') {
    return "Don't Buy";
  }
}

function buyStatusToClassName(buyStatus: BuyStatus) {
  if (buyStatus === 'buy') {
    return 'bg-green-500/20 text-green-500';
  } else if (buyStatus === 'consider') {
    return 'bg-yellow-500/20 text-yellow-700';
  } else if (buyStatus === 'no') {
    return 'bg-red-500/20 text-red-500';
  }
}

function SidelineSwapValueGuideDataTable({
  categoryItem,
  isLoading,
  valueGuide,
  valueGuideParameters,
}: {
  categoryItem?: FullCategory;
  isLoading: boolean;
  valueGuide?: ValueGuideMeta;
  valueGuideParameters: Record<string, any>;
}) {
  const { values } = useFormikContext<CreateItemFormValues>();
  const [recentlySoldModalOpen, setRecentlySoldModalOpen] = useState(false);
  const [moreSourcesModalOpen, setMoreSourcesModalOpen] = useState(false);

  const str = getSellThroughRate(valueGuide);
  const turn = valueGuide
    ? Math.round(
        (valueGuide.available_items || 0) /
          ((valueGuide.recent_sales || 1) / 12),
      )
    : null;

  const searchUrl = encodeURIComponent(
    values.title ||
      (categoryItem
        ? generateItemTitle(values, categoryItem)
        : [
            values.brand?.label,
            values.model?.label,
            values.category?.label
              ? getLast(values.category.label.split(' > '))
              : '',
          ]
            .filter(Boolean)
            .join('+')),
  );

  const { data: retailValueGuideData } = useQuery(
    ['retail-value-guide', valueGuideParameters],
    () => fetchRetailValueGuide(valueGuideParameters),
  );

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingIndicator size="large" className="mx-auto" />
      </div>
    );
  }

  if (!valueGuide) {
    return null;
  }

  let buyStatus: BuyStatus | undefined;
  buyStatus = 'consider';

  let timeToSellPillColor: 'green' | 'yellow' | 'red' | undefined;
  let timeToSellLowMediumHigh: 'Low' | 'Medium' | 'High' | undefined;
  let strLowMediumHigh: 'Low' | 'Medium' | 'High' | undefined;
  let strPillColor: 'green' | 'yellow' | 'red' | undefined;
  let turnPillColor: 'green' | 'yellow' | 'red' | undefined;

  if (valueGuide?.time_to_sell) {
    // TODO: Put these time to sell calculations in its own function
    const calculated0to30 =
      (valueGuide.time_to_sell.dom_0_30 / valueGuide.time_to_sell.total_items) *
      100;
    const calculated30to90 =
      (valueGuide.time_to_sell.dom_30_90 /
        valueGuide.time_to_sell.total_items) *
      100;
    const calculated90plus =
      (valueGuide.time_to_sell.dom_90 / valueGuide.time_to_sell.total_items) *
      100;

    if (
      calculated0to30 > calculated30to90 &&
      calculated0to30 > calculated90plus
    ) {
      timeToSellLowMediumHigh = 'High';
      timeToSellPillColor = 'green';
    } else if (
      calculated30to90 > calculated0to30 &&
      calculated30to90 > calculated90plus
    ) {
      timeToSellLowMediumHigh = 'Medium';
      timeToSellPillColor = 'yellow';
    } else if (
      calculated90plus > calculated0to30 &&
      calculated90plus > calculated30to90
    ) {
      timeToSellLowMediumHigh = 'Low';
      timeToSellPillColor = 'red';
    }
  }

  if (str != null) {
    if (str >= SELL_THROUGH_RATE_HIGH) {
      strLowMediumHigh = 'High';
      strPillColor = 'green';
    } else if (str >= 40) {
      strLowMediumHigh = 'Medium';
      strPillColor = 'yellow';
    } else {
      strLowMediumHigh = 'Low';
      strPillColor = 'red';
    }
  }

  if (turn != null) {
    if (turn < 16) {
      turnPillColor = 'green';
    } else if (turn < 24) {
      turnPillColor = 'yellow';
    } else if (turn >= 24) {
      turnPillColor = 'red';
    }
  }

  const countCx = 'px-4 py-2';

  return (
    <div className="rounded bg-grey-200 p-4">
      <div className="mb-4 text-4xl">Value Guide</div>

      <div className="mb-2 flex justify-between gap-16 rounded bg-white px-4 py-2.5">
        <div className="flex-1">
          <table className="w-full">
            <tbody>
              <tr className="bg-grey-100 ">
                <td className={`font-semibold ${countCx}`}>
                  Currently Available
                </td>
                <td className={countCx}>
                  {valueGuide?.available_items != null
                    ? thousandsSeparator(valueGuide.available_items)
                    : '--'}
                </td>
                <td className={countCx}>
                  <NewTabLink
                    href={`https://sidelineswap.com/search?src=trade-in-tool&${qsStringify(
                      valueGuideParameters,
                      {
                        addQueryPrefix: false,
                      },
                    )}`}
                    className="text-sm font-semibold underline"
                  >
                    (View{' '}
                    <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
                    )
                  </NewTabLink>
                </td>
              </tr>

              <tr>
                <td className={`font-semibold ${countCx}`}>Recent Listings</td>
                <td className={countCx}>
                  {thousandsSeparator(
                    valueGuide?.recently_published_items ?? 0,
                  )}
                </td>
                <td />
              </tr>

              <tr className="bg-grey-100">
                <td className={`font-semibold ${countCx}`}>Recent Sales</td>
                <td className={countCx}>
                  {valueGuide.recent_sales != null
                    ? thousandsSeparator(valueGuide.recent_sales)
                    : '--'}
                </td>
                <td className={countCx}>
                  <button
                    className="flex items-center space-x-1 underline"
                    type="button"
                    onClick={() => setRecentlySoldModalOpen(true)}
                  >
                    <div>View All</div>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex-1 space-y-2 text-right md:mt-0">
          <div className="flex justify-end">
            <Button
              variant="outlined"
              className="flex items-center space-x-1 underline"
              onClick={() => setMoreSourcesModalOpen(true)}
            >
              <div>Show More Sources</div>
              <ArrowTopRightOnSquareIcon className="h-4 w-4" />
            </Button>
          </div>

          <div className="flex justify-end">
            <NewTabLink href={`https://google.com/search?q=${searchUrl}`}>
              <Button
                variant="outlined"
                className="flex items-center space-x-1 underline"
              >
                <div>Check Google</div>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </Button>
            </NewTabLink>
          </div>

          <div className="flex justify-end">
            <NewTabLink
              href={`https://www.ebay.com/sch/i.html?_nkw=${searchUrl}&ssPageName=GSTL&LH_Sold=1&LH_Complete=1&rt=nc&LH_ItemCondition=4`}
            >
              <Button
                variant="outlined"
                className="flex items-center space-x-1 underline"
              >
                <div>Check eBay</div>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </Button>
            </NewTabLink>
          </div>
        </div>
      </div>

      <div className="mb-2 grid grid-cols-3 gap-2">
        <div className="flex flex-col rounded bg-white p-4">
          <div className="space-between mb-2 flex flex-1">
            <span className="font-semibold">Avg. Resale Price</span>
          </div>

          <div>
            {valueGuide.statistics ? (
              <AvgResalePriceBar valueGuide={valueGuide} />
            ) : (
              'N/A'
            )}
          </div>
        </div>

        <div className="flex flex-col rounded bg-white p-4">
          <div className="flex items-baseline">
            <div className="flex-1 font-semibold">Sell-Through Rate</div>
            <div>
              {str != null && strLowMediumHigh && strPillColor && (
                <LowMediumHighPill
                  label={strLowMediumHigh}
                  color={strPillColor}
                />
              )}
            </div>
          </div>

          <div className="mb-4 text-sm text-grey-500">
            Turn (weeks):{' '}
            {turn && turn != Infinity && turnPillColor ? (
              <LowMediumHighPill
                label={turn.toString()}
                color={turnPillColor}
              />
            ) : (
              'N/A'
            )}
          </div>

          <div className="flex-1" />

          <div>
            {valueGuide ? (
              <SellThroughRateBar valueGuide={valueGuide} />
            ) : (
              'N/A'
            )}
          </div>
        </div>

        <div className="flex flex-col rounded bg-white p-4">
          <div className="mb-4 flex items-baseline flex-1">
            <div className="flex-1 font-semibold">Time to Sell</div>

            <div>
              {timeToSellLowMediumHigh && timeToSellPillColor && (
                <LowMediumHighPill
                  label={timeToSellLowMediumHigh}
                  color={timeToSellPillColor}
                />
              )}
            </div>
          </div>

          <div>
            {valueGuide.time_to_sell ? (
              <TimeToSellBar timeToSell={valueGuide.time_to_sell} />
            ) : (
              'N/A'
            )}
          </div>
        </div>
      </div>

      {/*<div className="flex items-center rounded bg-white px-4 py-2">
        <div className="flex-1">
          Based on all the available information above, our recommendation is
          to:
        </div>

        <div
          className={`rounded-full px-3 py-1 text-sm font-semibold ${buyStatusToClassName(
            buyStatus,
          )}`}
        >
          {buyStatusToLabel(buyStatus)}
        </div>
      </div>*/}

      <RecentlySoldModal
        open={recentlySoldModalOpen}
        onClose={() => setRecentlySoldModalOpen(false)}
        platform="sidelineswap"
        valueGuideFields={valueGuideParameters}
      />

      <SimpleModal
        onClose={() => setMoreSourcesModalOpen(false)}
        open={moreSourcesModalOpen}
        title="More Sources"
        className="w-full max-w-4xl"
      >
        <ValueGuideDataTable valueGuideParameters={valueGuideParameters} />
      </SimpleModal>
    </div>
  );
}

function TimeToSellBar({ timeToSell }: { timeToSell: ValueGuideTimeToSell }) {
  const calculated0to30 = (timeToSell.dom_0_30 / timeToSell.total_items) * 100;
  const calculated30to90 =
    (timeToSell.dom_30_90 / timeToSell.total_items) * 100;
  const calculated90plus = (timeToSell.dom_90 / timeToSell.total_items) * 100;

  if (!calculated0to30 || !calculated30to90 || !calculated90plus) {
    return (
      <div className="text-center font-semibold text-slate-green-500">
        Not enough data
      </div>
    );
  }

  return (
    <div className="flex w-full">
      <div
        style={{
          width: calculated0to30 + '%',
        }}
      >
        <div className="text-xs text-slate-green-500">&lt;30</div>
        <div className="mb-1 text-sm">{calculated0to30.toFixed(1)}%</div>
        <div className="h-3 w-full bg-green-300" />
      </div>

      <div
        style={{
          width: calculated30to90 + '%',
        }}
      >
        <div className="text-xs text-slate-green-500">30-90</div>
        <div className="mb-1 text-sm">{calculated30to90.toFixed(1)}%</div>
        <div className="h-3 w-full bg-yellow-300" />
      </div>

      <div
        className="text-right"
        style={{
          width: calculated90plus + '%',
        }}
      >
        <div className="text-xs text-slate-green-500">90+</div>
        <div className="mb-1 text-sm">{calculated90plus.toFixed(1)}%</div>
        <div className="h-3 w-full bg-red-300" />
      </div>
    </div>
  );
}

function AvgResalePriceBar({ valueGuide }: { valueGuide: ValueGuideMeta }) {
  if (!valueGuide.statistics) {
    return null;
  }

  return (
    <div>
      <div className="mb-1 flex items-baseline">
        <div className="flex-1 text-sm">
          {displayPrice(valueGuide.statistics.p30)}
        </div>

        <div className="">
          <div className="relative mx-2 text-center">
            <div className="right-0 bottom-full rounded bg-turf-green-500 py-1 px-2 font-semibold text-white">
              {displayPrice(valueGuide.statistics.median)}
              <svg
                className="absolute left-0 top-full z-10 h-3 w-full text-turf-green-500"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xmlSpace="preserve"
              >
                <polygon
                  className="fill-current"
                  points="0,0 127.5,127.5 255,0"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex-1 text-right text-sm">
          {displayPrice(valueGuide.statistics.p70)}
        </div>
      </div>

      <div className="relative h-3 rounded bg-gradient-to-r from-red-300 via-yellow-300 to-green-300 shadow-inner" />
    </div>
  );
}

function SellThroughRateBar({ valueGuide }: { valueGuide: ValueGuideMeta }) {
  const str = getSellThroughRate(valueGuide) || 25;

  let calculated0to30: number | undefined;
  let calculated30to90: number | undefined;

  if (valueGuide.time_to_sell) {
    calculated0to30 =
      (valueGuide.time_to_sell.dom_0_30 / valueGuide.time_to_sell.total_items) *
      100;
    calculated30to90 =
      (valueGuide.time_to_sell.dom_30_90 /
        valueGuide.time_to_sell.total_items) *
      100;
  }

  return (
    <div>
      <div className="relative mb-1 flex">
        <div className="flex-1 text-xs text-slate-green-500">0%</div>

        <div
          className="absolute"
          style={{
            top: '-90%',
            left: `min(max(-12px, calc(${str}% - 2rem)), calc(100% - 2rem))`,
          }}
        >
          <div className="relative text-center">
            <div className="right-0 bottom-full rounded bg-turf-green-500 py-1 px-2 font-semibold text-white">
              {str}%
              <svg
                className="absolute left-0 top-full z-10 h-3 w-full text-turf-green-500"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xmlSpace="preserve"
              >
                <polygon
                  className="fill-current"
                  points="0,0 127.5,127.5 255,0"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex-1 text-right text-xs text-slate-green-500">
          100%
        </div>
      </div>

      <div className="relative h-3 rounded bg-gradient-to-r from-red-300 via-yellow-300 to-green-300 shadow-inner">
        {calculated0to30 != null && (
          <div
            style={{
              left: `${calculated0to30}%`,
            }}
            className="absolute h-full w-px bg-white"
          />
        )}

        {calculated30to90 != null && calculated0to30 != null && (
          <div
            style={{
              left: `${calculated0to30 + calculated30to90}%`,
            }}
            className="absolute h-full w-px bg-white"
          />
        )}
      </div>
    </div>
  );
}

function LowMediumHighPill({
  label,
  color,
}: {
  color: 'green' | 'yellow' | 'red';
  label: string;
}) {
  return (
    <div
      className={classNames(
        'rounded inline-block py-0.5 px-1.5 text-xs font-semibold uppercase',
        {
          'bg-green-100 text-green-500': color === 'green',
          'bg-yellow-100 text-yellow-500': color === 'yellow',
          'bg-red-100 text-red-500': color === 'red',
        },
      )}
    >
      {label}
    </div>
  );
}

export default SidelineSwapValueGuideDataTable;
