import React, { useState } from 'react';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import WarehouseAuditItemsTable from '../../../components/Warehouse/Audit/WarehouseAuditItemsTable';
import { Button, ButtonLink } from '../../../components/Form/Button';
import { Link } from 'react-router-dom';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import WarehouseAuditShipmentsTable from '../../../components/Warehouse/Audit/WarehouseAuditShipmentsTable';
import { Tab } from '../../../components/Form/Tab';
import { useSearchParam } from 'react-use';
import useSearchParamState from '../../../hooks/useSearchParamState';
import BackArrowLink from '../../../components/BackArrowLink';

interface Props {}

const WarehouseAuditMainPage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const [tab, setTab] = useSearchParamState<'items' | 'shipments'>('tab', {
    defaultValue: 'items',
    replace: true,
  });

  return (
    <WarehouseAuditLayout>
      <BackArrowLink to={`/warehouse/${warehouseId}`}>
        Back to Warehouse
      </BackArrowLink>

      <div className="mb-4 flex gap-4">
        <Tab selected={tab === 'items'} onClick={() => setTab('items')}>
          Tagged Items
        </Tab>

        <Tab selected={tab === 'shipments'} onClick={() => setTab('shipments')}>
          Incoming Shipments
        </Tab>
      </div>

      {tab === 'items' ? (
        <WarehouseAuditItemsTable />
      ) : (
        <div>
          <div className="flex mb-4">
            <div className="flex-1" />
            <ButtonLink to={`/warehouse/${warehouseId}/audit/transfers/scan`}>
              Scan Shipment
            </ButtonLink>
          </div>
          <WarehouseAuditShipmentsTable />
        </div>
      )}
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditMainPage;
