import { RedeemInfo } from './redeem';
import { Demand, Quality } from '../../constants/valueMatrix';
import { BonusCode } from './bonusCodes';
import { z } from 'zod';

export interface Item {
  id: number;
  name: string;
  type: string;
  filter_type: string;
  path: string;
  synonyms: any[];
  query: Object;
  category_name: string;
  available_count: number;
  thumb_url: string;
}

export interface QuoteListingMetadata {
  recent_sales?: number;
  time_to_sell?: string;
  avg_resale_price?: number;
  retail_value_guide?: number;
  sell_through_rate?: number;
  currently_available?: number;
  suggested_demand?: string | 'Do Not Buy';
}

export interface ListingItem {
  id?: string;
  item_id?: string;
  quote_id?: string;
  legacy_id?: number;
  legacy_item_id?: number;
  created_at?: string;
  deleted_at?: string; // Date
  category_id?: number | null;
  brand_id?: number | null;
  model_id?: number | null;
  details?: EditListingItemDetail[];
  model_version_id?: string | null;
  cash_offer_amount: number;
  credit_offer_amount: number;
  resale_amount: number;
  name?: string;
  quality?: Quality;
  demand?: Demand;
  quantity?: number;
  images?: string[];
  quote_completed_at?: string; // Date

  mpn?: string;
  metadata?: QuoteListingMetadata;

  // These are guesses at what the API is going to send.
  retail_price?: number | string;
  description?: string;
  estimated_trade_in?: number;
  tag?: string;
}

export interface EditListingItemDetail {
  option: string;
  option_id: number;
  option_slug: string;
  type: string;
  type_id: number;
  type_slug: string;
}

export interface EditListingItemDetailRequest {
  option_id: string | number;
  type_id: string | number;
}

export type PostItemRequest = Pick<
  ListingItem,
  | 'model_version_id'
  | 'cash_offer_amount'
  | 'resale_amount'
  | 'retail_price'
  | 'quality'
  | 'demand'
  | 'description'
  | 'name'
  | 'quantity'
  | 'credit_offer_amount'
  | 'estimated_trade_in'
  | 'mpn'
  | 'metadata'
> & {
  category_id?: number | string | null;
  brand_id?: number | string | null;
  model_id?: number | string | null;
  details: EditListingItemDetailRequest[];
};

export type PostItemResponse = Pick<
  ListingItem,
  | 'quote_id'
  | 'name'
  | 'category_id'
  | 'details'
  | 'quantity'
  | 'quality'
  | 'cash_offer_amount'
  | 'resale_amount'
  | 'retail_price'
  | 'mpn'
> & {
  id: number;
};

export interface PostSourceRequest {
  name: string;
  type: string;
}

export interface Category {
  id: string;
  legacy_id: number;
  full_name: string;
  relatable: boolean;
  image_url?: string;
  slug: string;
}

export type TitleName = string | '{BLANK}';

export interface Detail {
  id: string;
  legacy_id: number;
  legacy_brand_detail_id?: number; // only on models in "FullCategory"
  name: string;
  title_name?: TitleName;
  image_url?: string;
  slug: string;
}

export interface TradeInModel {
  id: string;
  legacy_id: number;
  name: string;
  slug: string;
  image_url?: string;
}

interface DetailGroup {
  id: string;
  legacy_id: number;
  name: string;
  required?: boolean;
  value_guides_required?: boolean;
  trade_in_required?: boolean;
  options: Detail[];
  title_order?: number;
  slug: string;
}

const DetailZ = z.array(
  z.object({
    id: z.string().uuid(),
    legacy_id: z.number(),
    name: z.string(),
    title_name: z.string().optional(),
    image_url: z.string().optional(),
    slug: z.string(),
  }),
);

const DetailGroupZ = z.array(
  z.object({
    id: z.string().uuid(),
    legacy_id: z.number(),
    name: z.string(),
    required: z.boolean().optional(),
    value_guides_required: z.boolean().optional(),
    trade_in_required: z.boolean().optional(),
    options: DetailZ,
    title_order: z.number().optional(),
    slug: z.string(),
  }),
);

export const TradeInModelVersionZ = z.object({
  id: z.string().uuid(),
  slug: z.string().nullish(),
  name: z.string(),
  details: DetailGroupZ,
  sku: z.string().nullish(),
  primary_image: z
    .object({
      id: z.number(),
      large_url: z.string(),
      small_url: z.string(),
      thumb_url: z.string(),
    })
    .nullish(),
  price_statistics: z.object({
    category_slug: z.string().nullish(),
    price_retail: z.string().nullish(),
    suggested_resale: z.string().nullish(),

    /** @deprecated */
    trade_good: z.string().nullish(),

    trade_list_excellent: z.string().nullish(),
    trade_list_good: z.string().nullish(),
    trade_list_new_no_tags: z.string().nullish(),
    trade_list_new_w_tags: z.string().nullish(),
    trade_list_very_good: z.string().nullish(),

    trade_cost_excellent: z.string().nullish(),
    trade_cost_good: z.string().nullish(),
    trade_cost_new_no_tags: z.string().nullish(),
    trade_cost_new_w_tags: z.string().nullish(),
    trade_cost_very_good: z.string().nullish(),
  }),
});

export type TradeInModelVersion = z.infer<typeof TradeInModelVersionZ>;

export interface FullCategory {
  brands?: Detail[];
  models?: Detail[];
  details?: DetailGroup[];

  id: string;
  legacy_id: number;
  full_name: string;
  relatable: boolean;

  title_name?: TitleName;
}

export interface Quote {
  id: string;
  created_at: string;
  listing_count: number;
  deleted_listing_count?: number;
  display_id: number;
  contact_id?: string;
  type: string;
  status: 'PENDING' | 'COMPLETED' | 'DELETED';
  trade_in_event_id: string;
  cash_offer_amount: number;
  credit_offer_amount: number;
  resale_estimate: number;
  submitted_by: string;

  /** @deprecated Only access quote_listings by getQuoteListings() */
  quote_listings?: ListingItem[];

  payouts?: QuotePayout[];
  redemption_code?: string;
  redemption_status?: RedeemInfo['status'];
  bonus_code?: BonusCode;
}

interface QuotePayout {
  payment_method: QuotePayoutMethod;
  payout_amount: number;
  charity?: string;
}

interface QuotePayoutMethod {
  name: string;
  payout_type: string;
  voidable?: boolean;
}

export interface Paging {
  total_pages: number;
  total_count: number;
  page_size: number;
  page: number;
  has_next_page: boolean;
}

export interface Payout {
  name?: string;
  amount?: number;
  payout_type?: string;
}

export interface ValueGuideTimeToSell {
  total_items: number;
  // Old data
  dom_7: number;
  dom_7_to_30: number;
  dom_30: number;

  // New data
  dom_0_30: number;
  dom_30_90: number;
  dom_90: number;
}

export interface ValueGuideMeta {
  statistics: null | ValueGuideData;

  /* 90 days */
  recent_sales: number;
  available_items: number;
  recently_published_items: number; // aka recent listings

  time_to_sell?: ValueGuideTimeToSell;
}

export interface ValueGuideData {
  max: number;
  median: number;
  min: number;
  p10: number;
  p20: number;
  p30: number;
  p40: number;
  p50: number;
  p60: number;
  p70: number;
  p80: number;
  p90: number;
  retail_price?: number;
}

export interface Source {
  id: string;
  type: string;
  name: string;
}
