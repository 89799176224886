import React, { useState } from 'react';
import WarehouseAuditLayout from '../../../../components/Layout/Layouts/WarehouseAuditLayout';
import Scanner from '../../../../components/Scanner/Scanner';
import { Button } from '../../../../components/Form/Button';
import getEnv from '../../../../utils/getEnv';
import { fetchTransferByTrackingNumber } from '../../../../api/partnerTransfers';
import { useNavigate } from 'react-router-dom';
import Message from '../../../../components/Form/Message';
import Field from '../../../../components/Form/Field';
import FieldLabel from '../../../../components/Form/FieldLabel';
import TagInput from '../../../../components/Partner/TagInput';
import Input from '../../../../components/Form/Input';
import FieldHint from '../../../../components/Form/FieldHint';

export default function WarehouseAuditTransferScanShipmentPage({}: {}) {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>();
  const [input, setInput] = useState('');

  async function handleSubmit(data: string) {
    /*
     Tracking number breakdown:

     | 8-digit Product Index
     |         | 12-digit Application Data
     |         |                  | 14-digit Track Number
     ↓         ↓                  ↓
     9632 0019 6 (069 749 3816) 1 00 2837 2324 6731

     Note: Actual scanned data does not include spaces or parentheses
     */

    let trackingNumber = data.slice(-14);
    if (trackingNumber.startsWith('00')) {
      trackingNumber = trackingNumber.slice(2);
    }

    try {
      const transfer = await fetchTransferByTrackingNumber(trackingNumber);
      navigate(`/warehouse/audit/transfers/${transfer.id}`);
    } catch (e) {
      setError('Transfer not found');
    }
  }

  return (
    <WarehouseAuditLayout exitButton>
      <h1 className="mb-4 text-2xl font-semibold">
        Scan Shipping Label Barcode
      </h1>

      <div className="mx-auto max-w-screen-sm">
        <Scanner mode="barcode" onScan={handleSubmit} />
      </div>

      {getEnv() !== 'production' && (
        <div className="my-4 flex justify-center">
          <Button onClick={() => handleSubmit('92346903255594000000000013')}>
            Test Scan
          </Button>
        </div>
      )}

      <form
        className="my-4 mx-auto max-w-sm"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(input);
        }}
      >
        <Field>
          <FieldLabel htmlFor="trackingNumber">Tracking Number</FieldLabel>
          <div className="flex space-x-4">
            <Input
              id="trackingNumber"
              value={input}
              onChange={e => setInput(e.target.value)}
            />
            <Button type="submit">Submit</Button>
          </div>

          <FieldHint>
            If the scanner isn't working, enter the tracking number here.
          </FieldHint>
        </Field>
      </form>

      {error && <Message error>{error}</Message>}
    </WarehouseAuditLayout>
  );
}
